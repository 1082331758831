//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from '@/components/Search'
import translations from "@/assets/translations.json";
// import translations from './translation.json'
export default {
    components: {Search},
    data() {
        return {
            confirmVisible: true,
			//uniacid:0,
            uniacid:parseInt(window.localStorage.getItem('uniacid')),
            //uniacid:19,
            orderData: null,
            paymentList: [
                { code: 'cod'},
                { code: 'noalipayhavepaypal'},
                { code: 'havealipayhavepaypal'}
            ],
            pay_type: '',
            dialogVisible: false,
            dialogPaypalVisible: false,
            currency: window.localStorage.getItem('currency'),
            paypalData:null,
            ordersn: '',
            paypalRendered: false,
            locale: window.localStorage.getItem('locale') || this.$i18n.locale,
        };
    },
    computed: {
    hardcodedLabels() {
        if (this.locale === 'EN') {
            return [
                'order_sn',
                'currency',
                'order_time',
                'pay_time',
                'cancel_time'
            ];
        } else {
            return this.orderData.info.data.list.map(item => item.label);
        }
    },
    hardcodedpayments() {
        if (this.locale === 'EN' ) {
            return [
                'Collect on delivery',
                'Paypal',
                'Balance payment',

            ]}
            else {
                return this.paymentList.map(item => item.name);
            }}
},
    created() {
		if(this.$route.query.order_id){
			 this.getOrderInfo(this.$route.query.order_id)
		}else{
			 this.getOrderInfo(this.$route.params.order)
		}
        var url = window.location.href;
        var dz_url = url.split("#")[0];
        var www_dz_url = dz_url.split("/")[2];
        var centerdomain= www_dz_url.split(".");
        if(centerdomain[0]=='alex' || this.uniacid == 15){
            this.yedpay=true;

            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AYk6yzGsSK4csLS6gaKLLTJOKLlqaLg7Qz8wn7eoCsCcbj_KJOeE-wdpeYNrR15cypT0FXt1pK5yJ0FM&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='nm' || this.uniacid == 17){
            this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AXtLnSrEsxn4TbzQ1dOt7kL7tBSQx5CrFBEGOabjJCdCp7Mr8HU1bqa-IisrDtCoTGKMMT45jjti4rJa&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='ace' || this.uniacid == 19){
            this.yedpay=true;

            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AVBI7idWVEcagwi3IvKwNTwAYFPcfguSr6LePapIWdZNuzAGZsYdj3IJB-m5eD-pK8YSnKeJOB5wTArv&currency=HKD");
            document.head.appendChild(tag);
        }
        else if(centerdomain[0]=='uf' || this.uniacid == 20){
            this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AU7iIVdB-LPIZ88IWIfG7fBGrzIlrdUZr5DM2--RGSM5XdEKjmgljhRnRJHgOnUE92XgdIky86lh5SUe&currency=HKD");
            document.head.appendChild(tag);

        }
        else if(centerdomain[0]=='hc' || this.uniacid == 22){
            this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=ATqYP_MtbQpiV0IzspbFqullRRZUhuw4wPKijkC4fglz7T6i87Lg_My0j5SMSifKxE7FZtEV86IX1xBQ&currency=HKD");
            document.head.appendChild(tag);

        }
        else if(centerdomain[0]=='trendy' || this.uniacid == 26){
            //this.yedpay=true;
            let tag = document.createElement("script");
            tag.setAttribute("src", "https://www.paypal.com/sdk/js?client-id=AZfZ-ex0qwP0r90lnVCapuIFXFUSvD5Y_hL1omoQzL4fyMyJfZFtiBm8_asg_WDBMd2lpWNc0vCkLocZ&currency=HKD");
            document.head.appendChild(tag);

        }

        
    },
    methods: {
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        translatestatus(statusName) {
        if (this.locale === 'EN') {
            // Mapping of Chinese names to English names
            const translations = {
                '待支付': 'Waiting for payment',
                "已支付": "Paid",
                '已取消': 'Cancelled',
            };
            return translations[statusName] || statusName; // Return translated name or original if not found
        }
        return statusName; // Return original name if locale is not 'EN'
    },
        confirmPayment() {
            this.confirmVisible = false; // Hide the Confirm button
            this.pay(); 
        },
        async getOrderInfo(order_sn) {
            const res = await this.$http.get('/order/detail', {order_sn})
            if (res.code !== 0) {
                return this.$message.error(res.message); 
            }
            let data = res.data
            this.orderData = data
            this.ordersn =this.orderData.basic.data.field.order_sn
        },
        //選擇支付方式
        async getPaymentList() {
			
			 //this.uniacid =20;
             //this.uniacid = 19;
            const res = await this.$http.get('/pay', {
                order_sn: this.orderData.basic.data.field.order_sn,v:5,uniacid:this.uniacid
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            this.paymentList = res.data.list
            this.dialogVisible = true
        },

        async pay() {
			console.log('paytype',this.pay_type);
            if(!this.pay_type){
                return this.$message.error('請選擇支付方式')
            }
			let gourl1="https://tvp-w7.nowaittech.com/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_payasia_pc_wechat&pay_type="+this.pay_type
			    gourl1+="&amount="+this.orderData.basic.data.field.order_amount
			    gourl1+="&order_sn="+this.orderData.basic.data.field.order_sn
			console.log('payasia....');
			
            if(this.pay_type=='yedpay'){
                let yedpayurl="https://tvp-w7.nowaittech.com/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=api_yedpay";
                yedpayurl+="&order_sn="+this.orderData.basic.data.field.order_sn
				console.log('yedpay');
				window.location.href= yedpayurl
				return 
			}
			if(this.pay_type=='payasia'){
				console.log('payasia....');
				window.location.href= gourl1
				return 
			}
			if(this.pay_type=='paypal'){
				let gourl="https://tvp-w7.nowaittech.com/app/index.php?i="+this.uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal33"
				    gourl+="&price="+this.orderData.basic.data.field.order_amount
				    gourl+="&tcid="+this.orderData.basic.data.field.order_sn
				console.log('paypal....');
                
                
                //this.dialogPaypalVisible=true;
				//window.location.href= gourl
                if (!this.paypalRendered) {
       
        this.showPaypal(this.gourl, this.uniacid, this.ordersn);
   
}
                /*
                paypal.Buttons({
                // Order is created on the server and the order id is returned
                createOrder() {
                    return fetch(gourl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        // use the "body" param to optionally pass additional order information
                        // like product skus and quantities
                        body: JSON.stringify({
                            cart: [
                                    {
                                    sku: "YOUR_PRODUCT_STOCK_KEEPING_UNIT",
                                    quantity: "YOUR_PRODUCT_QUANTITY",
                                    },
                                ],
                            }),
                        })
                        .then((response) => {
                            response.json();
                            console.log('responseeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
                            console.log(response.json());
                        })
                        .then((order) => {
                            order.id
                        });
                    }
                }).render('#paypal-button-container');*/
				return 
			}
			if(this.pay_type=='payasiazfb'){
				console.log('payasia....');
				window.location.href= gourl1
				return  
			}		
			if(this.pay_type=='payasiawechat'){
			
				window.location.href= gourl1
				return 
			}
            const res = await this.$http.post('/pay/'+this.pay_type, {
                order_sn: this.orderData.basic.data.field.order_sn
            })
            if(res.code == 0){
                 this.$message({message: res.message, type: 'success'})
                let timer = setTimeout(() => {
                    this.$router.push({name: 'User'})
                }, 500);
            }else{
                  return this.$message.error(res.message)
            }
        },
        showPaypal(gourl, uniacid, ordersn) {
            paypal.Buttons({
                    style: {
                        layout: 'horizontal'
                    },
                    createOrder: function(data, actions) {
                        return fetch(gourl, {
                            method: 'post'
                        }).then(function(res) {
                            return res.json();
                        }).then(function(orderData) {
                            console.log('orderDataaaaaaaaaaaaaaaaaa')
                            console.log(orderData)
                            //this.paypalData = orderData;
                            return orderData.id;
                        });
                    },
                    onApprove(data) {
                        console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
                        console.log(data)
                        console.log('data.orderID: ' + data.orderID)
                        let gourl2="https://tvp-w7.nowaittech.com/app/index.php?i="+uniacid+"&c=entry&m=yumi_jinxiaocun&do=paypal33_capture"
				        gourl2+="&tcid="+ordersn
                        gourl2+="&orderid="+data.orderID
                        console.log('gourl2: ' + gourl2)
                        return fetch(gourl2, {
                            method: "post",
                            mode: "no-cors",
                        })
                        .then((response) => {
                            console.log('responseeeeeee')
                            console.log(response)
                            response.json()
                        })
                        .then((orderData) => {
                            console.log('orderrrrrrrrrrrrrrrrrrrrrrr')
                            console.log(orderData)

                            let backurl = "/#/user?type=0";
                            window.location.href= backurl;


                            // Successful capture! For dev/demo purposes:
                            //console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                            //const transaction = orderData.purchase_units[0].payments.captures[0];
                            //alert(`Transaction ${transaction.status}: ${transaction.id}\n\nSee console for all available details`);
                            // When ready to go live, remove the alert and show a success message within this page. For example:
                            // const element = document.getElementById('paypal-button-container');
                            // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                            // Or go to another URL:  window.location.href = 'thank_you.html';
                        });
                    }
                }).render('#paypal-button-container').then(() => {
                this.paypalRendered = true;
                if( this.paypalRendered = true){
                    this.$confirm('click paypal yellow button to pay'), {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    type: 'info'
                
                    }}
            }).catch((error) => {
                console.error('PayPal button render failed:', error);
            });
        },
        handleClose() {
            this.dialogVisible = false;
            this.dialogPaypalVisible = false;
        }
    }
    
};
